import {Injectable} from '@angular/core';
import {Serializer} from '@matchsource/api-utils';
import {HlaHistoryDTO} from './hla-history.interfaces';
import {typingResultsSerializer} from './hla-history-typing-results.serializer';
import {HlaHistoryModel} from './hla-history.model';
import {RES_TEST_GROUP_NAME, RES_TEST_REASON, TEST_GROUP} from '@matchsource/models/hla';

@Injectable({
  providedIn: 'root',
})
export class HlaHistorySerializerService implements Serializer<HlaHistoryModel, HlaHistoryDTO> {
  fromDTO(input: HlaHistoryDTO): HlaHistoryModel {
    const testReason =
      input.testGroup === RES_TEST_GROUP_NAME ? RES_TEST_REASON : TEST_GROUP[input.testGroup]?.[input.testReason];
    return {
      reportingDate: input.reportingDate,
      typingDate: input.typingDate,
      requestedBy: input.requestedBy && input.requestedBy.identifier ? input.requestedBy.identifier : '',
      requestedByBpGuid: input.requestedBy?.bpGuid ?? '',
      submittedBy: input.submittedBy && input.submittedBy.identifier ? input.submittedBy.identifier : '',
      submittedByBpGuid: input.submittedBy?.bpGuid ?? '',
      testReason,
      correctionInd: input.correctionInd,
      typingResults: input.typingResults,
      seqNum: input.seqNum,
      hla: typingResultsSerializer.fromDTO(input.typingResults),
    };
  }
}
